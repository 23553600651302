<template>
  <v-card elevation="2" outlined class="mx-auto">
    <base-expansion-panel :inputs="inputs" :reactiveVar="reactiveVar">
      <base-form
        :searchBtn="true"
        :resetBtn="true"
        :inputs="inputs"
        :reactiveVar="reactiveVar"
        :numberOfCols="3"
        @submit-form="apiGetSeiyakuAnken"
      >
      </base-form>
    </base-expansion-panel>

    <base-table
      :headers="seiyakuAnkenKakunin.headers"
      :items="seiyakuAnkens"
      :columnName="[
        'item.konponaiyoshusei',
        'item.docDl',
        'item.shipping_bikou_flag',
        'item.unpaid_shipping',
        'item.comment_count',
        'item.picking_complete_flag',
        'item.bikou',
        'item.edit',
        'item.total_weight',
        'item.total_price',
        'item.status_name',
        'item.total_quantity',
      ]"
      minWidth="2200"
      :itemKey="itemKey"
      :itemClass="itemClass"
      multiSort
      showSelect
      @row-selected="
        (selected) => {
          this.rowSelected = selected;
        }
      "
    >
      <template v-slot:[`item.total_weight`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.total_weight != null">{{ item.total_weight.commaString('kg') }} </span>
        </td>
      </template>

      <template v-slot:[`item.total_quantity`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.total_quantity != null">{{ item.total_quantity.commaString() }}</span>
        </td>
      </template>

      <template v-slot:[`item.comment_count`]="{ item }">
        <v-lazy transition="fade-transition">
          <v-btn
            color="primary"
            x-small
            align="center"
            justify="center"
            @click="
              getDataSeiyakuAnkenCommentActions([item.packing_no]);
              rowComment = item;
              showModal = true;
            "
            >開く</v-btn
          >
        </v-lazy>
        <div class="comment-text" align="center" justify="center">
          {{ item.comment_count ? item.comment_count + '件' : '' }}
          {{ item.last_comment_date }}
        </div>
      </template>

      <template v-slot:[`item.picking_complete_flag`]="{ item }">
        <div v-if="item.cancel_flag === 0">
          <v-lazy v-if="!item.picking_complete_flag" transition="fade-transition">
            <v-btn
              :disabled="user.authority != 2 && user.authority != 9"
              color="warning"
              x-small
              align="center"
              justify="center"
              @click="showPickingCompConfModal(item)"
              >作業中</v-btn
            >
          </v-lazy>
          <v-lazy v-if="item.picking_complete_flag" transition="fade-transition">
            <v-btn
              :disabled="user.authority != 2 && user.authority != 9"
              color="primary"
              x-small
              align="center"
              justify="center"
              @click="showPickingWorkingConfModal(item)"
              >完了</v-btn
            >
          </v-lazy>
        </div>
      </template>

      <template v-slot:[`item.total_price`]="{ item }">
        <td style="text-align: right">
          <span v-if="item.total_price != null">{{ item.total_price.commaString('$') }}</span>
        </td>
      </template>

      <template v-slot:[`item.status_name`]="{ item }">
        <span v-bind:style="item.status === 0 ? 'color: red;' : ''">{{ item.status_name }}</span>
      </template>

      <template v-slot:[`item.unpaid_shipping`]="{ item }">
        <v-lazy transition="fade-transition">
          <span v-if="item.payment_date">入金済</span>
          <span v-else-if="item.unpaid_shipping">未入金出荷実施</span>
          <v-btn
            v-else-if="
              (role || user.authority === 3) && user.authority != 2 && item.status != 0 && item.cancel_flag != 1
            "
            color="red accent-4 white--text"
            x-small
            @click="
              updateUnpaidShippingTarget = item.packing_no;
              showConfirmModal();
            "
            >未入金出荷実施ボタン</v-btn
          >
        </v-lazy>
      </template>

      <template v-slot:[`item.konponaiyoshusei`]="{ item }">
        <v-lazy transition="fade-transition">
          <v-btn
            v-if="item.status !== 9 && item.status !== 6 && item.cancel_flag === 0"
            color="primary"
            x-small
            @click="redirectToKonpomeisai(item)"
            >選択</v-btn
          >
          <v-btn
            v-else-if="item.status !== 9 && item.status !== 6 && item.cancel_flag === 1 && item.editing"
            color="error"
            x-small
            @click="undoAnkenCancel(item.invoice_id, item.invoice_no)"
            >案件キャンセル取消</v-btn
          >
          <span v-else-if="item.status !== 9 && item.status !== 6 && item.cancel_flag === 1" style="color: red"
            >案件キャンセル</span
          >
        </v-lazy>
      </template>

      <template v-slot:[`item.docDl`]="{ item }">
        <v-lazy transition="fade-transition">
          <div class="d-flex justify-center align-center">
            <v-btn
              color="primary"
              x-small
              :disabled="item.status === 0"
              @click="
                downloadPackingExcels({
                  packing_nos: [item.packing_no],
                  download_all_flag: true,
                })
              "
              >dl</v-btn
            >
            <tool-tip
              v-if="item.no_battery_img_models && item.no_battery_img_models.length > 0"
              :message="item.no_battery_img_models?.join(',<br>')"
              classes="mx-2"
            ></tool-tip>
          </div>
        </v-lazy>
      </template>

      <template v-slot:[`item.shipping_bikou_flag`]="{ item }">
        <v-lazy transition="fade-transition">
          <div v-if="item.status != 0 && item.cancel_flag != 1" class="d-flex justify-center align-center">
            <v-checkbox
              v-if="item.shipping_description && item.editing"
              class="mt-0 pt-0"
              color="primary"
              :input-value="item.shipping_bikou_flag"
              dense
              hide-details
              :key="item.shipping_bikou_flag"
              @change="
                editSeiyakuAnken([
                  {
                    packing_no: item.packing_no,
                    new_shipping_bikou_flag: $event,
                  },
                ])
              "
            ></v-checkbox>
            <v-checkbox
              v-else-if="item.shipping_description"
              class="mt-0 pt-0"
              color="primary"
              :input-value="item.shipping_bikou_flag"
              dense
              hide-details
              disabled
            ></v-checkbox>
          </div>
        </v-lazy>
      </template>

      <template v-slot:[`item.bikou`]="{ item }">
        <v-textarea
          v-if="item.editing && role"
          class="pa-2 ma-0"
          :background-color="$vuetify.theme.dark ? 'black' : 'white'"
          rows="2"
          hide-details="true"
          :value="item.bikou"
          @change="editSeiyakuAnken([{ packing_no: item.packing_no, new_bikou: $event }])"
        ></v-textarea>
        <template v-else-if="item.bikou">
          <span style="line-break: anywhere" v-for="ele in item.bikou.split('\n')" :key="ele">{{ ele }}<br /></span>
        </template>
      </template>

      <template v-slot:[`item.edit`]="{ item }">
        <div v-if="role || user.authority === 3">
          <v-lazy transition="fade-transition">
            <v-btn
              v-if="item.editing"
              text
              icon
              color="green lighten-2"
              @click="
                updatePackingInfo([
                  {
                    packingNo: item.packing_no,
                    shippingBikouFlag: item.new_shipping_bikou_flag,
                    bikou: item.new_bikou,
                  },
                ])
              "
            >
              <v-icon large>mdi-content-save</v-icon>
            </v-btn>
            <v-btn
              v-else
              text
              icon
              color="blue lighten-2"
              @click="
                editSeiyakuAnken([
                  {
                    packing_no: item.packing_no,
                    editing: true,
                    new_shipping_bikou_flag: item.shipping_bikou_flag,
                    new_bikou: item.bikou,
                  },
                ])
              "
            >
              <v-icon large>mdi-pencil-box</v-icon>
            </v-btn>
          </v-lazy>
          <v-btn
            v-if="item.editing"
            text
            icon
            color="red lighten-2"
            @click="
              editSeiyakuAnken([
                {
                  packing_no: item.packing_no,
                  editing: false,
                },
              ])
            "
          >
            <v-icon large>mdi-close-circle</v-icon>
          </v-btn>
        </div>
      </template>
    </base-table>
    <commentModal
      :show-modal="showModal"
      :items="commentItems"
      :role="role"
      @close="closeModal"
      @commentRegist="commentRegist"
    >
    </commentModal>
    <v-card-actions class="justify-center mb-3">
      <input
        style="display: none"
        id="nyukinFileInput"
        ref="nyukinCsvImportRef"
        type="file"
        accept="text/csv"
        @change="importNyukinCsv"
      />
      <v-btn @click.stop="$refs.nyukinCsvImportRef.click()" color="success" v-if="role"
        >{{ labels.BTN_NYUKIN_CSV }}<v-icon>mdi-upload</v-icon></v-btn
      >
      <v-btn
        color="blue white--text"
        :disabled="rowSelected.length === 0"
        @click="
          downloadPackingExcels({
            packing_nos: rowSelected.map((row) => row.packing_no),
            download_all_flag: true,
          })
        "
        >{{ labels.MULTI_DOWNLOAD_BUTTON }}<v-icon>mdi-file-download</v-icon></v-btn
      >
      <csv-result-dl :disabled="seiyakuAnkens.length === 0" :row-selected="rowSelected"></csv-result-dl>
    </v-card-actions>
  </v-card>
</template>

<script>
// @ts-check
import { mapActions, mapGetters } from 'vuex';
import { inputs } from '../consts';
export default {
  name: 'SeiyakuAnkenKakunin',
  components: {
    commentModal: () => import(/* webpackChunkName: "commentModal" */ '../components/ui/commentModal.vue'),
    ToolTip: () => import(/* webpackChunkName: "toolTip" */ '../components/ui/ToolTip.vue'),
  },
  data() {
    return {
      itemKey: 'packing_no',
      updateUnpaidShippingTarget: null,
      ankenNoToUndoCancel: null,
      rowSelected: [],
      showModal: false,
      rowComment: null,
      role: false,
      reactiveVar: {
        invoiceDate: [null, null],
        ankenNo: '',
        buyerName: '',
        status: null,
        progress: null,
        shippingRemarkAri: false,
        taiozumi: false,
      },
      updatePackingStatus: null,
      /** @type {0 | 1 | null} */
      currentPickingCompFlag: null,
      pushEnterFlag: false,
    };
  },
  computed: {
    ...mapGetters({
      seiyakuAnkenKakunin: 'defined/seiyakuAnkenKakunin',
      seiyakuAnkens: 'seiyakuAnken/seiyakuAnken',
      commonSearchConditions: 'common/searchConditions',
      commentItems: 'seiyakuAnken/commentItems',
      labels: 'defined/labels',
      statusItems: 'defined/statusItems',
      user: 'defined/user',
      messages: 'defined/messages',
      path: 'defined/pathNames',
      clickedConfirm: 'ui/clickedConfirm',
    }),
    searchConditions() {
      return this.commonSearchConditions?.seiyakuAnken;
    },
    progress() {
      return this.searchConditions.progress;
    },
    inputs() {
      return inputs.seiyakuAnkenKakunin(this.statusItems);
    },
  },
  async created() {
    this.role = await this.getRole({
      page: this.path.SEIYAKU_ANKEN_KAKUNIN,
      authority: this.user.authority,
    });
    this.reactiveVar = this.searchConditions;
  },
  methods: {
    ...mapActions({
      toggleShowModal: 'ui/toggleShowModal',
      setModalTitle: 'ui/setModalTitle',
      setModalMessage: 'ui/setModalMessage',
      getDataSeiyakuAnkenCommentActions: 'seiyakuAnken/getDataSeiyakuAnkenCommentActions',
      setModalConfirmBtnLabel: 'ui/setModalConfirmBtnLabel',
      resetModalContents: 'ui/resetModalContents',
      setClickedConfirmFlag: 'ui/setClickedConfirmFlag',
      setUploadLoadingStatus: 'ui/setUploadLoadingStatus',
      editSeiyakuAnken: 'seiyakuAnken/editSeiyakuAnken',
      apiGetSeiyakuAnken: 'api/getSeiyakuAnken',
      updatePackingInfo: 'api/updatePackingInfo',
      downloadPackingExcels: 'api/downloadPackingExcels',
      updatePackingPaymentDate: 'api/updatePackingPaymentDate',
      apiUndoCancelAnken: 'api/undoCancelAnken',
      registCommentActions: 'seiyakuAnken/registCommentActions',
      parseCsv: 'common/parseCsv',
      getRole: 'common/getRole',
      setCustomErrorMessage: 'ui/setCustomErrorMessage',
      apiUpdatePackingStatus: 'api/updatePackingStatus',
      setSuccess: 'ui/setSuccess',
    }),

    onKeyDown(event) {
      if (event.code === 'Enter' && this.currentPickingCompFlag === 0) {
        this.setClickedConfirmFlag(true);
        this.toggleShowModal();
        this.pushEnterFlag = true;
      }
    },
    //
    async importNyukinCsv(file) {
      const data = await this.parseCsv(file.target.files[0]);
      const response = await this.updatePackingPaymentDate(
        data.map((row, index) => {
          const [paymentDate, matterNo, memberId, , quantity] = row;
          return {
            index,
            matterNo,
            paymentDate,
            memberId,
            quantity,
          };
        }),
      );
      // @ts-ignore
      this.$refs.nyukinCsvImportRef.value = '';
      if (response.errors && response.errors.length > 0) {
        this.setCustomErrorMessage(
          response.errors
            .map((error) => {
              return `${error.index + 1}行目:${error.message}`;
            })
            .join('\n '),
        );
      }
      this.editSeiyakuAnken(response.success);
      await this.apiGetSeiyakuAnken(this.reactiveVar);
    },

    //
    showConfirmModal() {
      this.toggleShowModal();
      this.setModalTitle(this.labels.UPDATE_CONFIRM);
      this.setModalMessage(this.messages.UPDATE_CONFIRM);
      this.setModalConfirmBtnLabel(this.labels.UPDATE);
    },

    // ピッキング完了確認モーダル表示
    showPickingCompConfModal(item) {
      this.currentPickingCompFlag = item.picking_complete_flag;
      const msg =
        '案件番号： ' +
        item.matter_no +
        '\n' +
        'バイヤー名: ' +
        item.member_name_ja +
        '\n' +
        this.messages.UPDATE_PICKING_COMP_CONFIRM;
      this.updatePackingStatus = { packingNo: item.packing_no, status: 1 };
      this.toggleShowModal();
      this.setModalTitle(this.labels.UPDATE_CONFIRM);
      this.setModalMessage(msg);
      this.setModalConfirmBtnLabel(this.labels.YES);
    },

    // ピッキング作業中確認モーダル表示
    showPickingWorkingConfModal(item) {
      this.currentPickingCompFlag = item.picking_complete_flag;
      const msg = '案件番号： ' + item.matter_no + '\n' + this.messages.UPDATE_PICKING_WORK_CONFIRM;
      this.updatePackingStatus = { packingNo: item.packing_no, status: 0 };
      this.toggleShowModal();
      this.setModalTitle(this.labels.UPDATE_CONFIRM);
      this.setModalMessage(msg);
      this.setModalConfirmBtnLabel(this.labels.YES);
    },

    //
    redirectToKonpomeisai(item) {
      this.$router.push({
        name: this.path.KONPOMEISAI,
        params: {
          invoiceNo: item.matter_no,
          id: item.invoice_id,
        },
      });
    },

    //
    itemClass(item) {
      if (item.editing) {
        return 'highlight-green not-change-hover-color';
      }
      return '';
    },

    //
    undoAnkenCancel(invoiceId, invoiceNo) {
      const cancelledSameAnkenNos = this.seiyakuAnkens.filter(
        ({ invoice_no, cancel_flag }) => invoice_no === invoiceNo && cancel_flag === 0,
      );
      if (cancelledSameAnkenNos?.length > 0) {
        this.setCustomErrorMessage(this.messages.M0005);
      } else {
        this.ankenNoToUndoCancel = invoiceId;
        this.setModalTitle(this.labels.UPDATE_CONFIRM);
        this.setModalMessage(this.messages.UNDO_ANKEN_CANCEL_CONFIRM);
        this.setModalConfirmBtnLabel(this.labels.BTN_CONFIRM);
        this.toggleShowModal();
        this.apiUndoCancelAnken(invoiceId);
      }
    },

    //
    closeModal() {
      this.commentItems = [];
      this.rowComment = null;
      this.showModal = false;
    },

    //
    async commentRegist(val, noticeTeams = []) {
      await this.registCommentActions({
        comment: val,
        packingNo: this.rowComment.packing_no,
        matterNo: this.rowComment.matter_no,
        noticeTeams: noticeTeams,
      });
      this.apiGetSeiyakuAnken(this.reactiveVar);
      this.closeModal();
    },
  },
  watch: {
    async clickedConfirm(value) {
      if (value && this.updateUnpaidShippingTarget) {
        await this.updatePackingInfo([
          {
            packingNo: this.updateUnpaidShippingTarget,
            unpaidShipping: 1,
          },
        ]);
        this.toggleShowModal();
        this.updateUnpaidShippingTarget = null;
        await this.apiGetSeiyakuAnken(this.reactiveVar);
      }
      if (value && this.ankenNoToUndoCancel) {
        await this.apiUndoCancelAnken(this.ankenNoToUndoCancel);
        this.toggleShowModal();
        this.ankenNoToUndoCancel = null;
        await this.apiGetSeiyakuAnken(this.reactiveVar);
      }
      if (value && this.updatePackingStatus) {
        await this.apiUpdatePackingStatus([
          {
            packingNo: this.updatePackingStatus.packingNo,
            status: this.updatePackingStatus.status,
          },
        ]);
        this.updatePackingStatus = null;
        await this.apiGetSeiyakuAnken(this.reactiveVar);
        this.setSuccess(false);
        this.currentPickingCompFlag = null;
        if (!this.pushEnterFlag) {
          this.toggleShowModal();
        }
        this.pushEnterFlag = false;
      }
      this.setClickedConfirmFlag(false);
    },
    searchConditions(newVal) {
      this.reactiveVar = newVal;
    },
  },
  mounted() {
    document.addEventListener('keydown', this.onKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeyDown);
  },
};
</script>
<style>
.wrap-text {
  white-space: pre;
}
.comment-text {
  font-size: xx-small;
}
</style>
